import React, { FC, useEffect, useMemo, useRef, useState } from 'react'

import { drumsAutocompleteInnerRoute, melodyAutocompleteInnerRoute } from '../../../../api/ai-playground'
import DrumsLabelClapsIcon from '../../../../assets/icons/drummer/claps.svg'
import DrumsLabelHihatsIcon from '../../../../assets/icons/drummer/hihats.svg'
import DrumsLabelKicksIcon from '../../../../assets/icons/drummer/kicks.svg'
import DrumsLabelOpenhapsIcon from '../../../../assets/icons/drummer/openhaps.svg'
import DrumsLabelPercsIcon from '../../../../assets/icons/drummer/percs.svg'
import DrumsLabelSnaresIcon from '../../../../assets/icons/drummer/snares.svg'
import { useLimits } from '../../../../context/LimitsContext'
import useDebounce from '../../../../hooks/useDebounce'
import { getApiChordProg } from '../../../../utils/api'
import { getTicksFromBars } from '../../../../utils/audio/midiUtils'
import {
  drumsToPianoRollNotes,
  getMaxSamplerMidi,
  getSamplerNames,
  getSamplerNamesReverse,
  pianoRollNotesToDrums,
} from '../../../../utils/drumsUtils'
import { getInstrumentKey, SingleInstrumentKey } from '../../../../utils/instrumentsUtils'
import { getPartLengthInBars, updateProgPartDataById } from '../../../../utils/progUtils'
import { Chord, defaultDrums, defaultMelody } from '../../../../utils/types'
import { useInteractivePianoRoll } from '../../../ai-playground/InteractivePianoRoll/hooks/useInteractivePianoRoll'
import { PianoRollNote, SimplePianoRollNote } from '../../../ai-playground/InteractivePianoRoll/types'
import { drumsPianoRollId } from '../../DrumsEditor/DrumsEditor'
import { INSTRUMENT_TYPES, InstrumentType } from '../../LayersOfInstruments/LayersOfInstruments'
import { melodyPianoRollId } from '../../MelodyEditor/MelodyEditor'
import {  usePlayerConfigState } from '../../hooks/usePlayerConfigState'

const SAMPLER_MODE_ICONS: Record<string, React.ReactNode> = {
  kicks: <DrumsLabelKicksIcon style={{ color: '#1bd190' }} />,
  snares: <DrumsLabelSnaresIcon style={{ color: '#1bd190' }} />,
  hihats: <DrumsLabelHihatsIcon style={{ color: '#1bd190' }} />,
  claps: <DrumsLabelClapsIcon style={{ color: '#1bd190' }} />,
  openhats: <DrumsLabelOpenhapsIcon style={{ color: '#1bd190' }} />,
  percs: <DrumsLabelPercsIcon style={{ color: '#1bd190' }} />,
}

type Props = {
  mode: InstrumentType

  setVelocity?: (v: number) => void
  setSelectedNotes?: (v: PianoRollNote[]) => void
}

const EditorPianoRollSetup: FC<Props> = ({ mode, setVelocity = () => {}, setSelectedNotes = () => {} }) => {
  const { isDrumsAutocompleteLimited, triggerLimitCallback } = useLimits()
  const {
    playerConfig: {
      prog,
      currentPart,
      currentPartId,
      player,
      activeChordIds,
      tonalityKey,
      tonalityScale,
      isPlaying,
      chordGenre,
      drumGenre,
    },
    playerConfigSetter: { setActiveChordIds, setProg },
  } = usePlayerConfigState()
  const {
    ppq,
    midiRangeMin,
    midiRangeMax,
    pianoRollNotes,
    selectedNoteIds,
    inactiveZoneStartTicks,
    setSelectedNoteIds,
    addNotes,
    removeAllNotes,
    onNoteSoundEvent,
    onPositionUpdateEvent,
    onNotesUpdateEvent,
    onNotesRemoveEvent,
    setInactiveZoneStartTicks,
    removeAllSmartScaleNotes,
    addSmartScaleNotes,
    removeAllAutocompleteNotes,
    addAutocompleteNotes,
    setRulerLabels,
    setSamplerModeIcons,
    setMidiRangeMax,
  } = useInteractivePianoRoll(mode === INSTRUMENT_TYPES.MELODY ? melodyPianoRollId : drumsPianoRollId)

  const abortControllerRef = useRef<AbortController | null>(null)

  const [isLoaded, setIsLoaded] = useState(false)
  const [autocompleteUsage, setAutocompleteUsage] = useState(0)

  const notesSelected = useMemo(() => Array.from(selectedNoteIds).length, [selectedNoteIds])
  const { SAMPLER_NAMES, SAMPLER_NAMES_REVERSE, SAMPLER_ICONS, SAMPLER_MAX_MIDI } = useMemo(() => {
    const { drums } = currentPart

    const SAMPLER_NAMES = getSamplerNames(drums)
    const SAMPLER_NAMES_REVERSE = getSamplerNamesReverse(drums)
    const SAMPLER_MAX_MIDI = getMaxSamplerMidi(drums)
    const SAMPLER_ICONS = Object.fromEntries(
      Object.entries(SAMPLER_NAMES).map(([trackMidi, trackName]) => [+trackMidi, SAMPLER_MODE_ICONS[trackName]]),
    )

    return { SAMPLER_NAMES, SAMPLER_NAMES_REVERSE, SAMPLER_ICONS, SAMPLER_MAX_MIDI }
  }, [currentPart.drums])

  const debouncedNotes: PianoRollNote[] = useDebounce(pianoRollNotes, 1000)

  const isMelodyMode = mode === INSTRUMENT_TYPES.MELODY
  const isDrumsMode = mode === INSTRUMENT_TYPES.DRUMS

  const isLimited = (() => {
    if (isMelodyMode) return false
    if (isDrumsMode) return isDrumsAutocompleteLimited(autocompleteUsage)
    return false
  })()

  const handleUpdateData = (newData: any) => {
    if (!prog) return

    const updates: any = {}

    if (isMelodyMode) {
      updates.melodyShown = true
      updates.melody = { ...(currentPart.melody || defaultMelody), ...newData }
    }
    if (isDrumsMode) {
      updates.drumsShown = true
      updates.drums = { ...(currentPart.drums || defaultDrums), ...newData }
    }

    const newProg = updateProgPartDataById(prog, currentPartId, updates)
    setProg(newProg)
  }

  // Set InteractivePianoRoll callbacks
  useEffect(() => {
    onNoteSoundEvent((note: PianoRollNote) => {
      if (isMelodyMode) player.playMelodyNote(note, currentPartId)
      if (isDrumsMode) {
        const layer = currentPart.drumLayers[0]
        const instrumentKey = layer ? getInstrumentKey(layer.instrument, 0, currentPartId) : SingleInstrumentKey
        const track = SAMPLER_NAMES[note.midiNote % SAMPLER_MAX_MIDI]

        player.drumsPlayer?.playBit([instrumentKey], track)
      }
    })
    onPositionUpdateEvent((ticks, fromRuler) => {
      if (fromRuler || !isPlaying) {
        const playChordFromNewPosition = player.setTime(ticks / (ppq * 4))
        playChordFromNewPosition()
      }
    })
    onNotesUpdateEvent(async (notes) => {
      if (isMelodyMode) handleUpdateData({ notes })
      if (isDrumsMode) handleUpdateData(pianoRollNotesToDrums(notes, currentPart.drums))
    })
    onNotesRemoveEvent((noteIds, notesLeft) => {
      if (isMelodyMode) handleUpdateData({ notes: notesLeft })
      if (isDrumsMode) handleUpdateData(pianoRollNotesToDrums(notesLeft, currentPart.drums))
    })
  }, [player, JSON.stringify(prog), currentPart, currentPartId, isPlaying])
  // Manage drums Piano Roll tracks
  useEffect(() => {
    if (!isDrumsMode) return

    setSamplerModeIcons(SAMPLER_ICONS)
    setMidiRangeMax(SAMPLER_MAX_MIDI)
  }, [SAMPLER_ICONS, SAMPLER_MAX_MIDI])
  // Set notes to and from InteractivePianoRoll
  useEffect(() => {
    if (!currentPart || notesSelected || isLoaded) return

    let notes = [] as SimplePianoRollNote[]

    if (isMelodyMode) notes = currentPart.melody?.notes || []
    if (isDrumsMode) notes = drumsToPianoRollNotes(currentPart.drums) || []

    setIsLoaded(true)
    removeAllNotes()
    addNotes(notes, { isSilent: true })
  }, [notesSelected, currentPart, pianoRollNotes, isLoaded])
  useEffect(() => {
    if (!currentPart || notesSelected) return

    let notes = [] as SimplePianoRollNote[]

    if (isMelodyMode) notes = currentPart.melody?.notes || []
    if (isDrumsMode) notes = drumsToPianoRollNotes(currentPart.drums) || []

    removeAllNotes()
    addNotes(notes, { isSilent: true })
  }, [currentPartId])
  // Set velocity state once notes in InteractivePianoRoll selected
  useEffect(() => {
    if (notesSelected) {
      const selectedNotes = pianoRollNotes.filter((note) => Array.from(selectedNoteIds).includes(note.id))
      const selectedNotesVelocities = selectedNotes.map((note) => note.velocity)
      const averageVelocityOfSelectedNotes = Math.floor(
        selectedNotesVelocities.reduce((sum, val) => (sum += val), 0) / selectedNotesVelocities.length,
      )

      setVelocity(averageVelocityOfSelectedNotes)
      setSelectedNotes(selectedNotes)
    } else {
      setVelocity(0)
      setSelectedNotes([])
    }
  }, [notesSelected, selectedNoteIds])
  // Unselect chords once notes in InteractivePianoRoll selected
  useEffect(() => {
    if (notesSelected) setActiveChordIds([])
  }, [notesSelected])
  // Unselect notes in InteractivePianoRoll once chord selected
  useEffect(() => {
    if (activeChordIds.length) setSelectedNoteIds(new Set())
  }, [activeChordIds])
  // Manage inactive zone in InteractivePianoRoll & smart scale notes
  useEffect(() => {
    const partDurationInBars = getPartLengthInBars(prog, currentPartId) || 4
    const partDurationInTicks = partDurationInBars * ppq * 4

    if (partDurationInTicks !== inactiveZoneStartTicks) setInactiveZoneStartTicks(partDurationInTicks)
    if (isDrumsMode) return

    const rulerLabels: any[] = []

    const prevChords: Chord[] = []
    const playbackNotes = currentPart.chords.flatMap((chord) => {
      const prevChordsDurationInBars = prevChords.reduce((duration, chord) => (duration += chord.duration || 0), 0)
      const startTicks = getTicksFromBars(prevChordsDurationInBars)
      const endTicks = getTicksFromBars(prevChordsDurationInBars + (chord.duration || 0))

      const chordPlaybackNotes = chord.midi.map((midiNote) => {
        return {
          midiNote,
          startTicks,
          endTicks,
          color: 'ghost-dashed',
          velocity: chord.settings.velocity * 127,
        }
      })

      rulerLabels.push({ text: chord.name, startTicks, endTicks })
      prevChords.push(chord)

      return chordPlaybackNotes
    })

    const octavesTotal = (midiRangeMax - midiRangeMin) / 12
    const octavesPadding = midiRangeMin / 12

    const smartScaleOctaves = Array.from({ length: octavesTotal }, (_, i) => i + octavesPadding)
    const allSmartScaleNotes = playbackNotes.flatMap((timing) => {
      return smartScaleOctaves.map((octave) => {
        return { ...timing, midiNote: (timing.midiNote % 12) + octave * 12 }
      })
    })

    const uniqueSmartScaleNotes = Array.from(
      new Map(
        allSmartScaleNotes.map((note) => [`${note.midiNote}-${note.startTicks}-${note.endTicks}`, note]),
      ).values(),
    )

    removeAllSmartScaleNotes()
    addSmartScaleNotes(uniqueSmartScaleNotes)
    setRulerLabels(rulerLabels)
  }, [JSON.stringify(prog), currentPart, currentPartId, inactiveZoneStartTicks])
  // Autocomplete
  useEffect(() => {
    ;(async () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort() // Cancel the previous request
      }

      abortControllerRef.current = new AbortController()
      const { signal } = abortControllerRef.current

      try {
        const startOfLastBar = inactiveZoneStartTicks - ppq * 4
        const areNotesInLastBar = debouncedNotes.some((note) => note.endTicks > startOfLastBar)

        if (debouncedNotes.length < 2 || debouncedNotes.length > 30 || areNotesInLastBar || !prog) return

        let autocompleteNotes = [] as any[]

        if (isMelodyMode) {
          autocompleteNotes = await melodyAutocompleteInnerRoute(
            debouncedNotes,
            getApiChordProg(prog),
            tonalityKey || 'A',
            tonalityScale || 'minor',
            chordGenre.name,
            signal,
          ).then((res) => res.pianoRollNotes)
        }
        if (isDrumsMode) {
          autocompleteNotes = await drumsAutocompleteInnerRoute(
            debouncedNotes.map((note) => ({ ...note, type: SAMPLER_NAMES[note.midiNote % SAMPLER_MAX_MIDI] })),
            drumGenre.key,
            signal,
          ).then((res) =>
            res.drumRollNotes.map((note: any) => ({ ...note, midiNote: SAMPLER_NAMES_REVERSE[note.type] })),
          )
        }

        if (!autocompleteNotes.length) return

        removeAllAutocompleteNotes()
        addAutocompleteNotes(autocompleteNotes)
      } catch (error) {
        console.error('Error in melodyAutocompleteInnerRoute:', error)
      }
    })()
  }, [debouncedNotes])

  return <></>
}

export default EditorPianoRollSetup
