import React, { ReactNode, useMemo, useRef, useState } from 'react'

import { useInteractivePianoRoll } from '../components/ai-playground/InteractivePianoRoll/hooks/useInteractivePianoRoll'
import { drumsPianoRollId } from '../components/editor/DrumsEditor/DrumsEditor'
import { melodyPianoRollId } from '../components/editor/MelodyEditor/MelodyEditor'
import { drumsToPianoRollNotes } from '../utils/drumsUtils'
import {
  checkIfChangePart,
  checkIfChangeProg,
  checkIfChangeTrack,
  checkIfPartsAreDuplicates,
  getUndoRedoDescription,
  handleChangePart,
  handleChangeProg,
  handleChangeTrack,
  historyUpdateDebounceTime,
  insertElementInArray,
  moveElementInArray,
  OperationEntity,
  TCheckers,
  TMoveType,
  TOperationKey,
  TOperators,
  validateProgAfterHistoryUpdate,
} from '../utils/history'
import { copyObj } from '../utils/stringUtils'
import { defaultDrums, Prog } from '../utils/types'
import { useProjectState } from './ProjectStateContext'

type FetchType = {
  prog: Prog | null
  setProg: (prog: Prog | null) => void

  changedPartId: number | null
  statePointer: number

  isUndoAvailable: boolean
  isRedoAvailable: boolean

  undoDescription: string
  redoDescription: string

  undo: () => void
  redo: () => void
}

export const HistoryContext = React.createContext<FetchType>({
  prog: null,
  setProg: () => {},

  changedPartId: null,
  statePointer: 0,

  isUndoAvailable: false,
  isRedoAvailable: false,

  undoDescription: '',
  redoDescription: '',

  undo: () => {},
  redo: () => {},
})

export const HistoryProvider = ({ children }: { children: ReactNode }) => {
  const { duplicatePartToProg, removePartFromProg } = useProjectState()
  const { removeAllNotes: removeAllMelodyNotes, addNotes: addMelodyNotes } = useInteractivePianoRoll(melodyPianoRollId)
  const { removeAllNotes: removeAllDrumsNotes, addNotes: addDrumsNotes } = useInteractivePianoRoll(drumsPianoRollId)

  const historyUpdateTimeoutRef = useRef<any>(null)
  const lastSavedStateRef = useRef<Prog | null>(null)

  const [state, setState] = useState<Prog | null>(null)
  const [statePointer, setStatePointer] = useState<number>(0)

  const [operations, setOperations] = useState<OperationEntity[][]>([])
  const [changedPartId, setChangedPartId] = useState<number | null>(null)

  const isUndoAvailable = useMemo(() => statePointer > 0 && operations.length > 0, [statePointer, operations])
  const isRedoAvailable = useMemo(() => statePointer <= operations.length - 1, [statePointer, operations])

  const undoDescription = useMemo(() => {
    if (!isUndoAvailable) return ''

    const undoOperations = operations[statePointer - 1]

    return getUndoRedoDescription(undoOperations)
  }, [isUndoAvailable, statePointer, operations])
  const redoDescription = useMemo(() => {
    if (!isRedoAvailable) return ''

    const redoOperations = operations[statePointer]

    return getUndoRedoDescription(redoOperations)
  }, [isRedoAvailable, statePointer, operations])

  // useEffect(() => {
  //   console.log(operations)

  //   const operationsLength = operations.map((innerOperations) => JSON.stringify(innerOperations).length)
  //   const totalLength = operationsLength.reduce((acc, v) => (acc += v), 0)
  //   console.log(totalLength, operationsLength)
  // }, [operations])

  // useEffect(() => {
  //   console.log(statePointer, operations.length)
  // }, [statePointer])

  // NEW STATE
  function setHistoryState(newState: Prog | null, isSilent = false) {
    // case 0: error update
    if (newState === null) return

    // case 1: prog initialization
    if (state === null) {
      setState(newState)
      lastSavedStateRef.current = newState
      return
    }

    // case 2: silent prog update
    if (isSilent) {
      setState(newState)
      return
    }

    // case 3: prog update with history registration
    setState(newState)
    clearTimeout(historyUpdateTimeoutRef.current)
    historyUpdateTimeoutRef.current = setTimeout(() => innerUpdateHistoryState(newState), historyUpdateDebounceTime)
  }
  const innerUpdateHistoryState = (newState: Prog) => {
    const lastSavedState = lastSavedStateRef.current
    if (lastSavedState === null) return

    const newOperations = Object.values(checkers)
      .map((handleCheck) => handleCheck(lastSavedState, newState))
      .filter(Boolean) as OperationEntity[]

    if (newOperations.length) {
      setStatePointer((oldStatePointer) => {
        setOperations((oldOperations) => [...oldOperations.slice(0, oldStatePointer), newOperations])
        return oldStatePointer + 1
      })
      lastSavedStateRef.current = newState
    }

    clearTimeout(historyUpdateTimeoutRef.current)
    historyUpdateTimeoutRef.current = null
  }

  // UNDO / REDO
  const handleMoveOnHistory = (moveType: TMoveType) => {
    const isUndo = moveType === 'undo'

    if ((isUndo && !isUndoAvailable) || (!isUndo && !isRedoAvailable)) return

    const newStatePointer = isUndo ? statePointer - 1 : statePointer + 1
    const operationPointer = isUndo ? statePointer - 1 : statePointer
    const newOperations = operations[operationPointer]

    let newState = JSON.parse(JSON.stringify(state)) as Prog
    let changedPartId: number | null = null

    newOperations.forEach((newOperation) => {
      const operationKey = newOperation.key
      const newValue = isUndo ? newOperation.oldState : newOperation.newState
      const handleCompleteOperation = operators[operationKey]

      if (operationKey === 'set-melody') {
        const newMelodyNotes = JSON.parse(newValue['melody.notes'])

        removeAllMelodyNotes()
        addMelodyNotes(newMelodyNotes, { isSilent: true })
      }
      if (operationKey === 'set-drums') {
        const newDrumsNotes = drumsToPianoRollNotes({
          ...(defaultDrums as any),
          groups: JSON.parse(newValue['drums.groups']),
        })

        removeAllDrumsNotes()
        addDrumsNotes(newDrumsNotes, { isSilent: true })
      }

      newState = handleCompleteOperation(operationKey, newValue, newState)
      changedPartId = getChangedPartId(operationKey, newValue) || changedPartId
    })

    newState = validateProgAfterHistoryUpdate(newState)

    setStatePointer(newStatePointer)
    setState(newState)
    setChangedPartId(changedPartId)

    lastSavedStateRef.current = newState
  }
  const getChangedPartId = (operationKey: TOperationKey, newValue: any) => {
    switch (operationKey) {
      case 'rename-prog':
      case 'set-prog-key':
      case 'set-prog-scale':
      case 'new-part':
      case 'set-part-loops':
      case 'rename-part':
      case 'duplicate-part':
      case 'delete-part':
      case 'drag-part':
        return null
      case 'set-genre':
      case 'set-volume':
      case 'show-hide-track':
      case 'set-length':
      case 'set-tempo':
      case 'set-chords':
      case 'set-melody':
      case 'set-drums':
      case 'set-instruments':
        return newValue.partId
    }
  }

  //     =====     PROG     =====

  // RENAME_PROG
  const checkIfRenameProg = (oldState: Prog, newState: Prog): OperationEntity | null => {
    return checkIfChangeProg('rename-prog', 'name', oldState, newState)
  }
  const handleRenameProg = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    return handleChangeProg('rename-prog', key, 'name', newValue, state)
  }
  // SET_PROG_KEY
  const checkIfSetProgKey = (oldState: Prog, newState: Prog): OperationEntity | null => {
    return checkIfChangeProg('set-prog-key', 'key', oldState, newState)
  }
  const handleSetProgKey = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    return handleChangeProg('set-prog-key', key, 'key', newValue, state)
  }
  // SET_PROG_SCALE
  const checkIfSetProgScale = (oldState: Prog, newState: Prog): OperationEntity | null => {
    return checkIfChangeProg('set-prog-scale', 'scale', oldState, newState)
  }
  const handleSetProgScale = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    return handleChangeProg('set-prog-scale', key, 'scale', newValue, state)
  }
  // SET_PROG_VOLUME
  const checkIfSetProgVolume = (oldState: Prog, newState: Prog): OperationEntity | null => {
    return checkIfChangeProg('set-prog-volume', 'volume', oldState, newState)
  }
  const handleSetProgVolume = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    return handleChangeProg('set-prog-volume', key, 'volume', newValue, state)
  }
  // SET_PROG_BPM
  const checkIfSetProgBpm = (oldState: Prog, newState: Prog): OperationEntity | null => {
    return checkIfChangeProg('set-prog-bpm', 'bpm', oldState, newState)
  }
  const handleSetProgBpm = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    return handleChangeProg('set-prog-bpm', key, 'bpm', newValue, state)
  }
  // SET_PROG_METRONOME_ENABLED
  const checkIfSetProgMetronomeEnabled = (oldState: Prog, newState: Prog): OperationEntity | null => {
    return checkIfChangeProg('set-prog-metronome-enabled', 'isMetronomeEnabled', oldState, newState)
  }
  const handleSetProgMetronomeEnabled = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    return handleChangeProg('set-prog-metronome-enabled', key, 'isMetronomeEnabled', newValue, state)
  }

  //     =====     PART     =====

  // ADD_DELETE_PART
  const checkIfNewPart = (oldState: Prog, newState: Prog): OperationEntity | null => {
    const oldPartsIds = oldState.parts.map((oldPart) => oldPart.id)
    const newPartsIds = newState.parts.map((newPart) => newPart.id)

    const newPartId = newPartsIds.find((newPartId) => !oldPartsIds.includes(newPartId))
    const newPart = newState.parts.find((newPart) => newPart.id === newPartId)

    const isNewPartDuplicateOfAny =
      newPart && oldState.parts.some((oldPart) => checkIfPartsAreDuplicates(oldPart, newPart))

    if (!newPartId || !newPart || isNewPartDuplicateOfAny) return null
    return {
      key: 'new-part',
      oldState: { partId: newPartId, part: null },
      newState: { partId: newPartId, part: JSON.stringify(newPart) },
    }
  }
  const handleNewPart = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    if (key !== 'new-part') return state

    const newState = JSON.parse(JSON.stringify(state)) as Prog

    if (newValue.part === null) {
      newState.parts.pop()
    } else {
      newState.parts.push(JSON.parse(newValue.part))
    }

    return newState
  }
  // SET_PART_LOOPS
  const checkIfSetPartLoops = (oldState: Prog, newState: Prog): OperationEntity | null => {
    return checkIfChangePart('set-part-loops', ['loops'], oldState, newState)
  }
  const handleSetPartLoops = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    return handleChangePart('set-part-loops', key, ['loops'], newValue, state)
  }
  // RENAME_PART
  const checkIfRenamePart = (oldState: Prog, newState: Prog): OperationEntity | null => {
    return checkIfChangePart('rename-part', ['name'], oldState, newState)
  }
  const handleRenamePart = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    return handleChangePart('rename-part', key, ['name'], newValue, state)
  }
  // DUPLICATE_PART
  const checkIfDuplicatePart = (oldState: Prog, newState: Prog): OperationEntity | null => {
    const [oldPart, newPart] = oldState.parts
      .map((oldPart, index) => {
        const potentialProgenitor = newState.parts[index + 1]
        const progenitorPart = checkIfPartsAreDuplicates(oldPart, potentialProgenitor) ? potentialProgenitor : null
        const isOldProgenitor = oldState.parts.find(
          (oldPart) => JSON.stringify(oldPart) === JSON.stringify(progenitorPart || {}),
        )

        if (!progenitorPart || isOldProgenitor) return null
        return [oldPart, progenitorPart]
      })
      .filter(Boolean)[0] || [null, null]

    if (!oldPart || !newPart) return null
    return {
      key: 'duplicate-part',
      oldState: { partId: newPart.id, duplicateExists: false },
      newState: { partId: oldPart.id, duplicateExists: true },
    }
  }
  const handleDuplicatePart = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    if (key !== 'duplicate-part') return state

    const newState = JSON.parse(JSON.stringify(state)) as Prog

    if (newValue.duplicateExists) {
      newState.parts = (duplicatePartToProg(newState, newValue.partId) as Prog).parts
    } else {
      newState.parts = (removePartFromProg(newState, newValue.partId) as Prog).parts
    }

    return newState
  }
  // DELETE_PART
  const checkIfDeletePart = (oldState: Prog, newState: Prog): OperationEntity | null => {
    const oldPartsIds = oldState.parts.map((oldPart) => oldPart.id)
    const newPartsIds = newState.parts.map((newPart) => newPart.id)

    const deletedPartId = oldPartsIds.find((oldPartId) => !newPartsIds.includes(oldPartId))
    const deletedPart = oldState.parts.find((oldPart) => oldPart.id === deletedPartId)
    const deletedPartIndex = oldPartsIds.indexOf(deletedPartId || -1)

    if (!deletedPartId || !deletedPart) return null
    return {
      key: 'delete-part',
      oldState: { partId: deletedPartId, position: deletedPartIndex, part: JSON.stringify(deletedPart) },
      newState: { partId: deletedPartId, position: deletedPartIndex, part: null },
    }
  }
  const handleDeletePart = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    if (key !== 'delete-part') return state

    const newState = JSON.parse(JSON.stringify(state)) as Prog

    if (newValue.part === null) {
      newState.parts = newState.parts.filter((part) => part.id !== newValue.partId)
    } else {
      newState.parts = insertElementInArray(newState.parts, JSON.parse(newValue.part), newValue.position)
    }

    return newState
  }
  // DRAG_PART
  const checkIfDragPart = (oldState: Prog, newState: Prog): OperationEntity | null => {
    const oldPartsIds = oldState.parts.map((oldPart) => oldPart.id)
    const newPartsIds = newState.parts.map((newPart) => newPart.id)

    const isIdsSame = JSON.stringify(copyObj(oldPartsIds).sort()) === JSON.stringify(copyObj(newPartsIds).sort())
    const draggedPartId = newPartsIds.find((newPartId, index) => {
      const currentPosition = index
      const previoudPosition = oldPartsIds.indexOf(newPartId)

      const isMoved = previoudPosition !== currentPosition
      if (!isMoved) return false

      const isTwoPartsExchanged = oldPartsIds[currentPosition] === newPartsIds[previoudPosition]
      const isMovedMoreThenOnOnePlace = Math.abs(previoudPosition - currentPosition) > 1

      return isTwoPartsExchanged || isMovedMoreThenOnOnePlace
    })

    if (!isIdsSame || !draggedPartId) return null
    return {
      key: 'drag-part',
      oldState: { partId: draggedPartId, position: oldPartsIds.indexOf(draggedPartId) },
      newState: { partId: draggedPartId, position: newPartsIds.indexOf(draggedPartId) },
    }
  }
  const handleDragPart = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    if (key !== 'drag-part') return state

    const newState = JSON.parse(JSON.stringify(state)) as Prog
    newState.parts = moveElementInArray(
      newState.parts,
      newState.parts.findIndex((p) => p.id === newValue.partId),
      newValue.position,
    )

    return newState
  }

  //     =====     TRACK CONFIG     =====

  // SET_GENRE
  const checkIfSetGenre = (oldState: Prog, newState: Prog): OperationEntity | null => {
    return checkIfChangePart(
      'set-genre',
      ['generatorSettings.chordGenreKey', 'generatorSettings.drumGenreKey'],
      oldState,
      newState,
    )
  }
  const handleSetGenre = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    return handleChangePart(
      'set-genre',
      key,
      ['generatorSettings.chordGenreKey', 'generatorSettings.drumGenreKey'],
      newValue,
      state,
    )
  }
  // SET_VOLUME
  const checkIfSetVolume = (oldState: Prog, newState: Prog): OperationEntity | null => {
    return checkIfChangePart('set-volume', ['chordsVolume', 'melodyVolume', 'drumsVolume'], oldState, newState)
  }
  const handleSetVolume = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    return handleChangePart('set-volume', key, ['chordsVolume', 'melodyVolume', 'drumsVolume'], newValue, state)
  }
  // SHOW_HIDE_TRACK
  const checkIfShowHideTrack = (oldState: Prog, newState: Prog): OperationEntity | null => {
    return checkIfChangePart('show-hide-track', ['melodyShown', 'drumsShown'], oldState, newState)
  }
  const handleShowHideTrack = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    return handleChangePart('show-hide-track', key, ['melodyShown', 'drumsShown'], newValue, state)
  }
  // SET_LENGTH
  const checkIfSetLength = (oldState: Prog, newState: Prog): OperationEntity | null => {
    return checkIfChangePart('set-length', ['melody.length', 'drums.length'], oldState, newState)
  }
  const handleSetLength = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    return handleChangePart('set-length', key, ['melody.length', 'drums.length'], newValue, state)
  }
  // SET_TEMPO
  const checkIfSetTempo = (oldState: Prog, newState: Prog): OperationEntity | null => {
    return checkIfChangePart('set-tempo', ['melody.tempo', 'drums.tempo'], oldState, newState)
  }
  const handleSetTempo = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    return handleChangePart('set-tempo', key, ['melody.tempo', 'drums.tempo'], newValue, state)
  }

  //     =====     TRACK CONTENT     =====

  // SET_CHORDS
  const checkIfSetChords = (oldState: Prog, newState: Prog): OperationEntity | null => {
    return checkIfChangeTrack('set-chords', ['chords'], oldState, newState)
  }
  const handleSetChords = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    return handleChangeTrack('set-chords', key, ['chords'], newValue, state)
  }
  // SET_MELODY
  const checkIfSetMelody = (oldState: Prog, newState: Prog): OperationEntity | null => {
    return checkIfChangeTrack('set-melody', ['melody.notes'], oldState, newState)
  }
  const handleSetMelody = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    return handleChangeTrack('set-melody', key, ['melody.notes'], newValue, state)
  }
  // SET_DRUMS
  const checkIfSetDrums = (oldState: Prog, newState: Prog): OperationEntity | null => {
    return checkIfChangeTrack('set-drums', ['drums.groups'], oldState, newState)
  }
  const handleSetDrums = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    return handleChangeTrack('set-drums', key, ['drums.groups'], newValue, state)
  }
  // SET_INSTRUMENT
  const checkIfSetInstruments = (oldState: Prog, newState: Prog): OperationEntity | null => {
    return checkIfChangeTrack('set-instruments', ['chordLayers', 'melodyLayers', 'drumLayers'], oldState, newState)
  }
  const handleSetInstruments = (key: TOperationKey, newValue: any, state: Prog): Prog => {
    return handleChangeTrack('set-instruments', key, ['chordLayers', 'melodyLayers', 'drumLayers'], newValue, state)
  }

  const checkers: TCheckers = {
    'rename-prog': checkIfRenameProg,
    'set-prog-key': checkIfSetProgKey,
    'set-prog-scale': checkIfSetProgScale,
    'set-prog-volume': checkIfSetProgVolume,
    'set-prog-bpm': checkIfSetProgBpm,
    'set-prog-metronome-enabled': checkIfSetProgMetronomeEnabled,
    'new-part': checkIfNewPart,
    'set-part-loops': checkIfSetPartLoops,
    'rename-part': checkIfRenamePart,
    'duplicate-part': checkIfDuplicatePart,
    'delete-part': checkIfDeletePart,
    'drag-part': checkIfDragPart,
    'set-genre': checkIfSetGenre,
    'set-volume': checkIfSetVolume,
    'set-instruments': checkIfSetInstruments,
    'show-hide-track': checkIfShowHideTrack,
    'set-length': checkIfSetLength,
    'set-tempo': checkIfSetTempo,
    'set-chords': checkIfSetChords,
    'set-melody': checkIfSetMelody,
    'set-drums': checkIfSetDrums,
  }
  const operators: TOperators = {
    'rename-prog': handleRenameProg,
    'set-prog-key': handleSetProgKey,
    'set-prog-scale': handleSetProgScale,
    'set-prog-volume': handleSetProgVolume,
    'set-prog-bpm': handleSetProgBpm,
    'set-prog-metronome-enabled': handleSetProgMetronomeEnabled,
    'new-part': handleNewPart,
    'set-part-loops': handleSetPartLoops,
    'rename-part': handleRenamePart,
    'duplicate-part': handleDuplicatePart,
    'delete-part': handleDeletePart,
    'drag-part': handleDragPart,
    'set-genre': handleSetGenre,
    'set-volume': handleSetVolume,
    'set-instruments': handleSetInstruments,
    'show-hide-track': handleShowHideTrack,
    'set-length': handleSetLength,
    'set-tempo': handleSetTempo,
    'set-chords': handleSetChords,
    'set-melody': handleSetMelody,
    'set-drums': handleSetDrums,
  }

  return (
    <HistoryContext.Provider
      value={{
        prog: state,
        setProg: (prog) => setHistoryState(prog),

        changedPartId,
        statePointer,

        isUndoAvailable,
        isRedoAvailable,

        undoDescription,
        redoDescription,

        undo: () => handleMoveOnHistory('undo'),
        redo: () => handleMoveOnHistory('redo'),
      }}
    >
      {children}
    </HistoryContext.Provider>
  )
}

export const useHistory = () => React.useContext<FetchType>(HistoryContext)
