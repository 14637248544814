import React, { ReactNode, useEffect, useState } from 'react'

import useDebounce from '../hooks/useDebounce'
import { getActivePart, updateProgPartDataById } from '../utils/progUtils'
import { copyObj } from '../utils/stringUtils'
import {
  Chord,
  defaultProjectData,
  defaultLyrics,
  defaultProg,
  defaultView,
  getDefaultPart,
  Prog,
  ProgPart,
  ProgPartPattern,
  Project,
} from '../utils/types'
import { useInternationalization } from './InternationalizationContext'

type FetchType = {
  editedProjectId: string | null
  projectSaved: boolean
  draftSaved: boolean
  projectEmpty: boolean
  setEditedProjectId: (i: string | null) => void
  setProjectSaved: (b: boolean) => void
  setDraftSaved: (b: boolean) => void
  setProjectEmpty: (b: boolean) => void
  configOrProgChanged: number | null
  setConfigOrProgChanged: (a: number | null) => void
  saveChangedStateTrigger: number | null
  openNewProject: boolean
  setOpenNewProject: (v: boolean) => void
  isProjectSetuped: boolean
  setIsProjectSetuped: (v: boolean) => void

  getDefaultProg: () => Prog
  getDefaultProject: () => Project
  addPartToProg: (prog: Prog, name: string, fromEmpty?: boolean) => Prog
  removePartFromProg: (prog: Prog | null, partId: number) => Prog | null
  duplicatePartToProg: (prog: Prog | null, partId: number, duplicatePosition?: number) => Prog | null
  initiateProgFromData: (
    prog: Prog,
    newPart: Array<Chord>,
    scale: string,
    key: string,
    partIdProps: number,
    drums: ProgPartPattern,
  ) => Prog
}

export const ProjectStateContext = React.createContext<FetchType>({
  editedProjectId: null,
  projectSaved: true,
  projectEmpty: true,
  draftSaved: true,
  setProjectEmpty: (b: boolean) => {},
  setEditedProjectId: (i: string | null) => {},
  setProjectSaved: (b: boolean) => {},
  setDraftSaved: (b: boolean) => {},
  configOrProgChanged: null,
  setConfigOrProgChanged: (a: number | null) => {},
  saveChangedStateTrigger: null,
  openNewProject: false,
  setOpenNewProject: () => {},
  isProjectSetuped: false,
  setIsProjectSetuped: () => {},

  getDefaultProg: () => ({} as any),
  getDefaultProject: () => ({} as any),
  addPartToProg: () => ({} as any),
  removePartFromProg: () => ({} as any),
  duplicatePartToProg: () => ({} as any),
  initiateProgFromData: () => ({} as any),
})

export const ProjectStateProvider = ({ children }: { children: ReactNode }) => {
  const { getText, addComponentText, isTextReady } = useInternationalization()

  const [editedProjectId, setEditedProjectId] = useState<string | null>(null)
  const [isProjectSetuped, setIsProjectSetuped] = useState(false)
  const [draftSaved, setDraftSaved] = useState(true)
  const [projectSaved, setProjectSaved] = useState(true)
  const [projectEmpty, setProjectEmpty] = useState(true)
  const [openNewProject, setOpenNewProject] = useState(false)
  const [configOrProgChanged, setConfigOrProgChanged] = useState<number | null>(null)
  const saveChangedStateTrigger = useDebounce(configOrProgChanged, 3000)

  useEffect(() => {
    if (!configOrProgChanged) {
      return
    }
    setProjectSaved(false)
    setDraftSaved(false)
  }, [configOrProgChanged])
  useEffect(() => {
    if (!editedProjectId) {
      setConfigOrProgChanged(null)
    }
  }, [editedProjectId])
  useEffect(() => {
    addComponentText('ProjectStateProvider')
  }, [])

  const STRINGS = getText('ProjectStateProvider')
  if (!isTextReady(STRINGS)) return null

  const getDefaultProg = () => {
    return addPartToProg(
      {
        ...defaultProg,
        name: STRINGS.defaultProgName,
        componentKey: Math.random(),
        parts: [],
      },
      '',
      true,
    )
  }
  const getDefaultProject = (): Project => {
    return {
      id: '',
      name: STRINGS.defaultName,
      prog: getDefaultProg(),
      view: defaultView,
      lyrics: defaultLyrics,
      data: defaultProjectData,
      updatedAt: '',
    }
  }
  const addPartToProg = (prog: Prog, name: string, fromEmpty?: boolean) => {
    const newId = Math.max(...prog.parts.map((p) => p.id), 0) + 1
    const prevPart = prog.parts[prog.parts.length - 1]

    return {
      ...prog,
      parts: [
        ...prog.parts,
        {
          ...getDefaultPart(fromEmpty, prevPart?.melodyShown, prevPart?.drumsShown),
          name: name || (newId % 2 ? STRINGS.verse : STRINGS.chorus),
          id: newId,
        },
      ],
    }
  }
  const removePartFromProg = (prog: Prog | null, partId: number) => {
    if (!prog) return prog

    const newProg = {
      ...prog,
      parts: prog.parts.filter((p) => p.id !== partId),
    }
    if (newProg.parts.length === 0) {
      newProg.parts = getDefaultProg().parts
    }
    return newProg
  }
  const duplicatePartToProg = (prog: Prog | null, partId: number, duplicatePosition?: number) => {
    if (!prog) return prog

    const progenitorPart = prog.parts.find((part) => part.id === partId)
    const progenitorPartPosition = prog.parts.findIndex((part) => part.id === partId)
    if (!progenitorPart) return prog

    const newId = Math.max(...prog.parts.map((p) => p.id)) + 1
    const newPart = {
      ...progenitorPart,
      id: newId,
      name: `${STRINGS.copyOf} ${progenitorPart.name}`,
      chords: progenitorPart.chords.map((ch) => ({
        ...ch,
        id: (ch.id % 1000) + 1000 * newId,
      })),
    }
    const newPartPosition = duplicatePosition || progenitorPartPosition + 1

    const newProg = copyObj(prog)
    newProg.parts.splice(newPartPosition, 0, newPart)

    return newProg
  }
  const initiateProgFromData = (
    prog: Prog,
    newChords: Array<Chord>,
    scale: string,
    key: string,
    partIdProps: number,
    drums: ProgPartPattern,
  ) => {
    const newProg = prog.parts.length ? prog : addPartToProg(prog, '')
    const currentPartId = partIdProps || newProg.parts[0].id
    const currentPart = getActivePart(prog, currentPartId) as ProgPart

    const lockedChords = newChords.filter((ch) => ch.locked)
    const takenIds = lockedChords.map((l) => l.id)
    const getNextChordId = (index: number): number => {
      const chordId = index + 1 + 1000 * currentPartId
      if (takenIds.includes(chordId)) {
        return getNextChordId(index + 1)
      }
      takenIds.push(chordId)
      return chordId
    }

    const updates: any = {
      chords: newChords.map((ch: Chord, index: number) => {
        return {
          ...ch,
          // SHOULD KEEP THIS LOGIC
          id: ch.locked ? ch.id : getNextChordId(index),
          duration: ch.duration || 1,
        }
      }),
    }

    if (currentPart.drumsShown && drums) {
      updates.drums = {
        ...drums,
        length: newChords.length ? null : 4,
      }
    }

    return {
      ...updateProgPartDataById({ ...newProg, scale, key }, currentPartId, updates),
      componentKey: Math.random(),
    }
  }

  return (
    <ProjectStateContext.Provider
      value={{
        editedProjectId,
        projectSaved,
        projectEmpty,
        draftSaved,
        setDraftSaved,
        setEditedProjectId,
        setProjectSaved,
        setProjectEmpty,
        configOrProgChanged,
        setConfigOrProgChanged,
        saveChangedStateTrigger,
        openNewProject,
        setOpenNewProject,
        isProjectSetuped,
        setIsProjectSetuped,

        getDefaultProg,
        getDefaultProject,
        addPartToProg,
        removePartFromProg,
        duplicatePartToProg,
        initiateProgFromData,
      }}
    >
      {children}
    </ProjectStateContext.Provider>
  )
}

export const useProjectState = () => React.useContext<FetchType>(ProjectStateContext)
